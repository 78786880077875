<template>
  <div class="other-products-items flex flex-wrap justify-start animated fadeIn -mx-31">
    <div v-for="item in cards" :key="item.id" class="w-1/3 px-31 flex-none mb-62">
      <app-image-card v-bind="item.card" />
    </div>
  </div>
</template>

<script>
import AppImageCard from '@/components/AppImageCard.vue'

export default {
  name: 'OtherProductsItems',
  components: {
    AppImageCard,
  },
  props: {
    cards: {
      type: Array,
      required: true,
    },
  },
}
</script>

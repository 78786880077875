<template>
  <div class="app-image-card bg-white rounded-8 text-left" @click="redirectTo(url)">
    <img class="image rounded-t-8 h-40" :src="image.src" :alt="image.title" />
    <div class="body p-24">
      <h1 class="title flex items-center pb-8 text-blue-400 font-20 font-medium">
        <img v-if="icon" class="pr-10" :src="icon.src" :alt="icon.title" />
        <span class="leading-1">{{ title }}</span>
      </h1>
      <p class="text-16 text-grey-500" v-html="text" />
    </div>
  </div>
</template>

<script>
import redirectTo from '@/shared/utils/redirectTo'

export default {
  name: 'AppImageCard',
  props: {
    image: {
      required: true,
      type: Object,
    },
    icon: {
      type: Object,
    },
    title: {
      required: true,
      type: String,
    },
    text: {
      required: true,
      type: String,
    },
    url: {
      type: String,
    },
  },
  methods: {
    redirectTo,
  },
}
</script>

<style lang="scss" scoped>
.app-image-card {
  box-shadow: 0 4px 8px 1px theme('colors.grey.300');

  &:hover {
    cursor: pointer;
  }

  .image {
    width: 100%;
    object-fit: cover;
  }
}
</style>
